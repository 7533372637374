import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
import Browser from "../../../components/BrowserDemo";
import Lesson from "../../../components/Lesson";
export const meta = {
  published: true,
  publishedAt: "2020-03-24",
  title: "Margin considered harmful",
  summary: "We should ban margin from our React components. Hear me out.",
  image: "/static/images/margin.png",
  likes: 1711
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`We should ban margin from our components. Hear me out.`}</p>
    <p>{`Margin breaks component encapsulation. A well-built component should not affect anything outside itself.`}</p>
    <p>{`Margin makes reusability harder. Good components are usable in any context or layout.`}</p>
    <p>{`Margin conflicts with how designers think. Designers think about space in relation and context. They define how far a component should be from another component in a specific instance.`}</p>
    <p>{`By banning margin from all components you have to build more reusable and encapsulated components.`}</p>
    <h3>{`Moving responsibility`}</h3>
    <p>{`Instead of margin I have started using spacer components, which move the responsibility of managing space to the parent-level.`}</p>
    <p>{`For example, the Braid design system popularized the `}<a parentName="p" {...{
        "href": "https://seek-oss.github.io/braid-design-system/components/Stack"
      }}><inlineCode parentName="a">{`Stack`}</inlineCode>{` component`}</a>{`:`}</p>
    <pre {...{
      "className": "language-jsx"
    }}><code parentName="pre" {...{
        "className": "language-jsx"
      }}><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Stack`}</span></span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`space`}</span><span parentName="span" {...{
            "className": "token script language-javascript"
          }}><span parentName="span" {...{
              "className": "token script-punctuation punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "token number"
            }}>{`3`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
  `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Item`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
  `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Item`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
  `}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Item`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span><span parentName="code" {...{
          "className": "token plain-text"
        }}>{`
`}</span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`Stack`}</span></span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`
`}</code></pre>
    <p>{`Using spacer components has implications that are not obvious `}<em parentName="p">{`a priori`}</em>{`.`}</p>
    <p>{`Spacer components (such as `}<inlineCode parentName="p">{`Stack`}</inlineCode>{` above) can restrict spacing values to steps on a scale. That way, all spacing automatically aligns to the grid.`}</p>
    <p>{`Spacer components define how far a component should be from another component in a specific instance. You have to define space in relation and context.`}</p>
    <p>{`Who else thinks about space in relation and context? Designers.`}</p>
    <p>{`Spacer components bring us closer to how designers think. They make our designs more consistent and they force us to build more reusable and encapsulated components.`}</p>
    <p>{`Use spacer components. Ban margin.`}</p>
    <hr></hr>
    <p><em parentName="p">{`I am not the first one to realize this. Thanks to `}<a parentName="em" {...{
          "href": "https://twitter.com/markdalgleish"
        }}>{`@markdalgleish`}</a>{`, `}<a parentName="em" {...{
          "href": "https://twitter.com/michaeltaranto"
        }}>{`@michaeltaranto`}</a>{` and `}<a parentName="em" {...{
          "href": "https://twitter.com/mattcompiles"
        }}>{`@mattcompiles`}</a>{` at Seek, as well as my good friend `}<a parentName="em" {...{
          "href": "https://twitter.com/okonetchnikov"
        }}>{`@okonetchnikov`}</a>{` for paving the way and prompting me to think about it.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      